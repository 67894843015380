import React from 'react';
import cls from 'classnames';

import { Box } from 'wui/Box';

import { ImageRatio } from 'settings/consts';

import { classes } from './AspectRatio.st.css';

interface AspectRatioProps extends React.ComponentProps<typeof Box> {
  aspectRatio: ImageRatio;
}

export function AspectRatio({
  className,
  children,
  aspectRatio,
  ...rest
}: AspectRatioProps) {
  return (
    <Box
      className={cls(classes.root, className, {
        [classes.cinema]: aspectRatio === ImageRatio.rectangle,
        [classes.square]: aspectRatio === ImageRatio.square,
      })}
      {...rest}
    >
      <div className={classes.child}>{children}</div>
    </Box>
  );
}
