import React from 'react';
import { useSelector } from 'react-redux';

import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { groupsLivesiteClick } from '@wix/bi-logger-groups/v2';

import { IGroup } from 'store/groups';
import { selectIsJoinedGroupMember } from 'store/selectors';

import { Button, ButtonSize } from 'wui/Button';

import { GroupMembershipButton } from 'common/components/GroupMembership';
import { UISref } from 'common/router';
import { useBiParams } from 'common/hooks/useBiParams';

interface IGroupGridItemActionProps {
  group: IGroup;
  fullWidth?: boolean;
}

export function GroupGridItemAction({
  group,
  fullWidth,
}: IGroupGridItemActionProps) {
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();
  const biParams = useBiParams();

  const isJoined = useSelector(selectIsJoinedGroupMember(group.id as string));

  if (isJoined) {
    return (
      <UISref
        state="group"
        params={{ slug: group.slug }}
        bi={groupsLivesiteClick({
          screen_name: biParams.groupsScreenWithTab(),
          button_name: 'view_group',
        })}
      >
        <Button
          as="a"
          fullWidth={fullWidth}
          upgrade={!isMobile}
          size={isMobile ? ButtonSize.tiny : undefined}
          secondary={true}
        >
          {t('groups-web.btn.view-group')}
        </Button>
      </UISref>
    );
  }

  return (
    <GroupMembershipButton
      fullWidth={fullWidth}
      upgrade={!isMobile}
      size={isMobile ? ButtonSize.tiny : undefined}
      groupId={group.id as string}
      bi={groupsLivesiteClick({
        group_id: group.id as string,
        screen_name: biParams.groupsScreenWithTab(),
        button_name: 'join',
      })}
    />
  );
}

GroupGridItemAction.displayName = 'GroupGridItemAction';
