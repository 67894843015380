import React from 'react';
import cls from 'classnames';
import type { TPAComponentProps } from 'wix-ui-tpa/dist/src/types';

import { Fade } from '../Fade';

import { classes } from './Backdrop.st.css';

interface IBackdropProps extends TPAComponentProps {
  isOpen?: boolean;
  children?: React.ReactNode;

  onClick?(event: React.MouseEvent): void;
}

export function Backdrop(props: IBackdropProps) {
  const { className, isOpen, onClick } = props;

  return (
    <Fade appear in={isOpen} timeout={300}>
      <div
        aria-hidden
        onClick={onClick}
        data-hook={props['data-hook']}
        className={cls(classes.root, className)}
      >
        {props.children}
      </div>
    </Fade>
  );
}
