import { ISettingsParam } from '@wix/tpa-settings';
import {
  ImageCrop,
  ImageLayout,
  ImageRatio,
  LayoutType,
  TextAlignment,
  GroupsRequestSort,
} from '../consts';
import { groupsSettingsParams } from '../groups/settings';

export const WIDGET_NUM_GROUPS = 3;
export const WIDGET_MAX_NUM_GROUPS = 9;
export const TEXT_SPACING = 100;

export type IGroupsListWidgetSettingsParams = {
  sortBy: ISettingsParam<GroupsRequestSort>;
  imageRatio: ISettingsParam<ImageRatio>;
  groupListLayout: ISettingsParam<LayoutType>;
  gridCardSpacing: ISettingsParam<number>;
  imageLayout: ISettingsParam<ImageLayout>;
  imageCrop: ISettingsParam<ImageCrop>;
  textAlignment: ISettingsParam<TextAlignment>;
  textSpacing: ISettingsParam<number>;
  numberGroups: ISettingsParam<number>;

  showGroupType: ISettingsParam<boolean>;
  showActivity: ISettingsParam<boolean>;
  showButton: ISettingsParam<boolean>;
};

export const groupsListWidgetSettingsParams: IGroupsListWidgetSettingsParams = {
  /**
   * Widget Layout settings
   */
  sortBy: groupsSettingsParams.sortBy,
  numberGroups: {
    getDefaultValue: () => WIDGET_NUM_GROUPS,
    key: 'numberGroups',
  },
  imageRatio: groupsSettingsParams.imageRatio,
  groupListLayout: groupsSettingsParams.groupListLayout,
  gridCardSpacing: groupsSettingsParams.gridCardSpacing,
  imageCrop: {
    getDefaultValue: () => ImageCrop.CROP,
    key: 'imageCrop',
  },
  imageLayout: {
    getDefaultValue: () => ImageLayout.LTR,
    key: 'imageLayout',
  },
  textAlignment: {
    getDefaultValue: () => TextAlignment.Left,
    key: 'textAlignment',
  },
  textSpacing: {
    getDefaultValue: () => TEXT_SPACING,
    key: 'textSpacing',
  },
  /**
   * Widget Design settings
   */
  showActivity: {
    getDefaultValue: () => true,
    key: 'showActivity',
  },
  showGroupType: {
    getDefaultValue: () => true,
    key: 'showGroupType',
  },
  showButton: {
    getDefaultValue: () => true,
    key: 'showButton',
  },
};
