import React from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { Event as IEvent } from '@wix/ambassador-events-v1-event/types';

import { Button } from 'wui/Button';
import { AlertDialog } from 'wui/AlertDialog';
import { DialogTitle } from 'wui/DialogTitle';
import { DialogContent } from 'wui/DialogContent';
import { DialogContentText } from 'wui/DialogContentText';
import { DialogActions } from 'wui/DialogActions';

interface IPastEventsDialogProps
  extends React.ComponentProps<typeof AlertDialog> {
  events: IEvent[];
}

export function PastEventsDialog(props: IPastEventsDialogProps) {
  const { events, ...rest } = props;
  const { t } = useTranslation();

  const { isMobile } = useEnvironment();

  return (
    <AlertDialog {...rest}>
      <DialogTitle alert title={t('groups-web.restriction.invalid.title')} />
      <DialogContent>
        <DialogContentText>
          {t('groups-web.restriction.invalid.subtitle_icu', {
            count: events.length,
          })}
        </DialogContentText>
      </DialogContent>
      <DialogActions alert>
        <Button bw secondary onClick={props.onClose} fullWidth={isMobile}>
          {t('groups-web.cancel')}
        </Button>
      </DialogActions>
    </AlertDialog>
  );
}

PastEventsDialog.displayName = 'PastEventsDialog';
