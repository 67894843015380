import React from 'react';

interface IShowProps {
  if?: boolean;
  children: React.ReactNode;
}

export function Show(props: IShowProps) {
  const { if: condition, children, ...rest } = props;

  return condition ? (
    <>
      {React.isValidElement(children)
        ? React.cloneElement(children, rest)
        : children}
    </>
  ) : null;
}

Show.displayName = 'Show';
Show.defaultProps = {
  if: false,
};
