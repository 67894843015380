import React from 'react';
import cls from 'classnames';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import type { TPAComponentProps } from 'wix-ui-tpa/dist/src/types';

import { classes } from './ListItem.st.css';

interface IListItemProps extends TPAComponentProps {
  as?: React.ElementType;
  children: React.ReactNode;
  centered?: boolean;
  disablePadding?: boolean;
  disableGutters?: boolean;
  actionOnHover?: boolean;
}

export function ListItem(props: IListItemProps) {
  const { className, centered, disablePadding, actionOnHover, disableGutters } =
    props;

  const { isMobile } = useEnvironment();

  const Element = props.as as React.ElementType;

  return (
    <Element
      data-hook={props['data-hook']}
      className={cls(classes.root, className, {
        [classes.mobile]: isMobile,
        [classes.centered]: centered,
        [classes.disablePadding]: disablePadding,
        [classes.disableGutters]: disableGutters,
        [classes.hideAction]: actionOnHover,
      })}
    >
      {props.children}
    </Element>
  );
}

ListItem.displayName = 'ListItem';
ListItem.defaultProps = {
  as: 'li',
  centered: true,
};
