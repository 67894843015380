import React from 'react';
import cls from 'classnames';

import { Stack } from '../Stack';

import { classes } from './List.st.css';

interface IListProps extends React.ComponentProps<typeof Stack> {
  children: React.ReactNode;

  disablePadding?: boolean;
  disableGutters?: boolean;
}

export function List(props: IListProps) {
  const { disablePadding, disableGutters, className, ...rest } = props;

  return (
    <Stack
      as="ul"
      direction="vertical"
      className={cls(classes.root, className, {
        [classes.disablePadding]: disablePadding,
        [classes.disableGutters]: disableGutters,
      })}
      {...rest}
    >
      {props.children}
    </Stack>
  );
}

List.displayName = 'List';
List.defaultProps = {
  as: 'ul',
  gap: 'SP0',
};
