import React from 'react';

import { TPAComponentProps } from 'wix-ui-tpa/dist/src/types';

import { Box } from '../Box';

interface ICardActionsProps extends TPAComponentProps {
  children?: React.ReactNode;
}

export function CardActions({ children, ...rest }: ICardActionsProps) {
  return (
    <Box
      paddingTop="SP1"
      paddingLeft="SP6"
      paddingRight="SP6"
      paddingBottom="SP6"
      {...rest}
    >
      {children}
    </Box>
  );
}
