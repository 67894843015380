import React from 'react';
import cls from 'classnames';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import type { TPAComponentProps } from 'wix-ui-tpa/dist/src/types';

import { Typography } from '../Typography';

import { classes } from './DialogContentText.st.css';

interface IDialogContentTextProps extends TPAComponentProps {
  children?: React.ReactNode;
}

export function DialogContentText(props: IDialogContentTextProps) {
  const { isMobile } = useEnvironment();

  let text: React.ReactElement;

  if (props.children && typeof props.children === 'string') {
    text = (
      <Typography variant={isMobile ? 'p2-14' : 'p2-16'}>
        {props.children}
      </Typography>
    );
  } else {
    text = props.children as React.ReactElement;
  }

  return (
    <div data-hook={props['data-hook']} className={cls(classes.root)}>
      {text}
    </div>
  );
}
