import React from 'react';
import cls from 'classnames';

import { Paper } from '../Paper';

import type {
  OverridableComponent,
  OverridableComponentProps,
} from '../OverridableComponent';

import { classes } from './Card.st.css';

interface ICardProps extends React.ComponentProps<typeof Paper> {}

export const Card = React.forwardRef(
  (
    props: OverridableComponentProps<ICardProps, 'div'>,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const { as, className, children, ...rest } = props;

    return (
      <Paper ref={ref} className={cls(classes.root, className)} {...rest}>
        {children}
      </Paper>
    );
  },
) as OverridableComponent<ICardProps, 'div'>;

Card.displayName = 'Card';
