import React from 'react';

import type { TPAComponentProps } from 'wix-ui-tpa/dist/src/types';

import type {
  OverridableComponent,
  OverridableComponentProps,
} from '../OverridableComponent';
import type { Direction, Alignment, VerticalAlignment, Spacing } from './types';

import { stVars } from '../theme/spacing.st.css';
import { st, classes } from './Box.st.css';

interface IBoxProps extends TPAComponentProps {
  children?: React.ReactNode;

  flex?: boolean;
  inline?: boolean;
  wrap?: boolean;
  truncate?: boolean;

  direction?: Direction;
  align?: Alignment;
  verticalAlign?: VerticalAlignment;

  gap?: Spacing | number | string;

  padding?: Spacing | string;
  paddingTop?: Spacing;
  paddingLeft?: Spacing;
  paddingRight?: Spacing;
  paddingBottom?: Spacing;

  margin?: Spacing | string;
  marginTop?: Spacing;
  marginLeft?: Spacing;
  marginRight?: Spacing;
  marginBottom?: Spacing;

  width?: string | number;
  height?: string | number;
}

export const Box = React.forwardRef(
  (
    props: OverridableComponentProps<IBoxProps, 'div'>,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const {
      as = 'div',
      inline,
      wrap,
      truncate,
      direction,
      align,
      verticalAlign,
      width,
      height,
      className,
      ...rest
    } = props;

    const style = Object.fromEntries(
      Object.entries(props)
        .filter(([key]) =>
          [
            'gap',
            'paddingTop',
            'paddingLeft',
            'paddingRight',
            'paddingBottom',
            'padding',
            'marginTop',
            'marginLeft',
            'marginRight',
            'marginBottom',
            'margin',
          ].includes(key),
        )
        .map(([key, value]) => {
          return [key, getSpacingValue(value as string)];
        }),
    );

    const Element = as;

    return (
      <Element
        ref={ref}
        style={{
          ...style,
          width,
          height,
        }}
        className={st(
          classes.root,
          {
            simple: !props.flex,
            inline: inline as boolean,
            wrap: wrap as boolean,
            truncate: truncate as boolean,
            direction: direction as Direction,
            alignItems: align as Alignment,
            justifyContent: verticalAlign as VerticalAlignment,
          },
          className,
        )}
        {...rest}
      >
        {props.children}
      </Element>
    );
  },
) as OverridableComponent<IBoxProps, 'div'>;

function getSpacingValue(value: number | string = '') {
  if (typeof value === 'number') {
    return value;
  }

  return value
    .split(' ')
    .map((spacing) => stVars[spacing] || spacing)
    .filter((value) => value !== undefined)
    .join(' ');
}

Box.displayName = 'Box';
Box.defaultProps = {
  flex: true,
  direction: 'horizontal',
};
