import React from 'react';
import cls from 'classnames';

import { TPAComponentProps } from 'wix-ui-tpa/dist/src/types';

import { classes } from './ListItemIcon.st.css';

interface IListItemIconProps extends TPAComponentProps {
  children?: React.ReactNode;
}

export function ListItemIcon(props: IListItemIconProps) {
  const { children, className } = props;

  return (
    <div
      data-hook={props['data-hook']}
      className={cls(classes.root, className)}
    >
      {children}
    </div>
  );
}
