import React from 'react';
import { ErrorMonitorBoundary, WidgetProps } from '@wix/yoshi-flow-editor';
import { WixCommentsApiProvider } from '@wix/comments-ooi-client';

import { SocialGroupStore } from 'store/SocialGroupsStore';

import { PortalContext } from 'wui/Portal/context';
import { ErrorState } from 'wui/ErrorState';

import { Router } from 'common/router';
import { ControllerProvider } from 'common/context/controller';
import { ThemeProvider } from 'common/context/theme';

import { Toasts } from '../Toasts';
import type { IVMProps } from '../../../vm/types';
import { GroupMembershipDialogs } from '../GroupMembership';

interface ISocialGroupsAppProps extends WidgetProps<IVMProps> {
  children: React.ReactNode;
}

export function SocialGroupsApp(props: ISocialGroupsAppProps) {
  const { children, store, ...rest } = props;

  return (
    <ErrorMonitorBoundary isDebug fallback={<ErrorState unhandled />}>
      <SocialGroupStore state={store}>
        <Router>
          <ThemeProvider host={props.host}>
            <PortalContext.Provider value={props.host.id}>
              <ControllerProvider {...rest}>
                <WixCommentsApiProvider {...rest}>
                  {children}
                  <Toasts />
                  <GroupMembershipDialogs />
                </WixCommentsApiProvider>
              </ControllerProvider>
            </PortalContext.Provider>
          </ThemeProvider>
        </Router>
      </SocialGroupStore>
    </ErrorMonitorBoundary>
  );
}

SocialGroupsApp.displayName = 'SocialGroupsApp';
