import React, { FC } from 'react';
import { useBi, useEnvironment, WidgetProps } from '@wix/yoshi-flow-editor';

import { groupListLoaded } from '@wix/bi-logger-groups/v2';

import { COMPONENT } from 'settings/consts';

import { SocialGroupsApp } from 'common/components/SocialGroupsApp';

import { Fade } from 'wui/Fade';
import { Container } from 'wui/Container';

import type { IVMProps } from '../../../vm/types';

import { LayoutRoot } from '../../GroupsListWidget/Widget/Layout';

import { classes } from 'Groups/styles.st.css';

COMPONENT.name = 'SideBySideWidget';

const SideBySideWidget: FC<WidgetProps<IVMProps>> = (props) => {
  const { isMobile } = useEnvironment();

  const bi = useBi();

  React.useEffect(() => {
    bi.report(
      groupListLoaded({
        layout_type: 'side_by_side_widget',
      }),
    );
  }, []);

  return (
    <SocialGroupsApp {...props}>
      <Container
        fluid={isMobile}
        padding={isMobile ? 'SP0' : 'SP5'}
        className={!isMobile ? classes.appBackground : undefined}
        data-hook="SideBySide-wrapper"
      >
        <Fade key="sidebyside-widget" timeout={500}>
          <LayoutRoot />
        </Fade>
      </Container>
    </SocialGroupsApp>
  );
};

export default SideBySideWidget;
