import React from 'react';

import { useEnvironment } from '@wix/yoshi-flow-editor';

import { ImageResizeOptions } from 'wix-ui-tpa';

import { useSettings } from '@wix/tpa-settings/react';

import { ImageCrop, ImageRatio, TextAlignment } from 'settings/consts';
import { groupsSettingsParams as settingsParams } from 'settings/groups';
import { groupsListWidgetSettingsParams } from 'settings/groups-list-widget';

import widgetSettingsParams from '../../../../settingsParams';

import { IGroup } from 'store/groups';

import { Card } from 'wui/Card';
import { CardContent } from 'wui/CardContent';
import { Show } from 'wui/Show';
import { Box } from 'wui/Box';
import { Typography } from 'wui/Typography';

import { GroupInfo } from 'common/components/GroupInfo';
import { GroupImage } from 'common/components/GroupImage';
import { RoleIcon } from 'common/components/RoleIcon';
import { UISref } from 'common/router';

import { GroupGridItemAction } from 'Groups/Widget/Layout/GridLayout/GroupGrid/GroupGridItem/GroupGridItemAction';

import { getDirection } from './helpers';

import { classes as groupsStyles } from 'Groups/styles.st.css';
import { classes } from './GroupStripeItem.st.css';
import { Alignment } from 'wui/Box/types';
import { groupsLivesiteClick } from '@wix/bi-logger-groups/v2';
import { useBiParams } from 'common/hooks/useBiParams';

interface IGroupStripeItemProps {
  group: IGroup;
  even?: boolean;
}

const textAlignmentsToBoxAlignment: { [key in TextAlignment]: Alignment } = {
  [TextAlignment.Left]: 'left',
  [TextAlignment.Center]: 'center',
  [TextAlignment.Right]: 'right',
};

export function GroupStripeItem({ group, even }: IGroupStripeItemProps) {
  const { isMobile } = useEnvironment();
  const biParams = useBiParams();

  const settings = useSettings();

  const imageLayout = settings.get(widgetSettingsParams.imageLayout);
  const alignment =
    textAlignmentsToBoxAlignment[
      settings.get(widgetSettingsParams.textAlignment) as TextAlignment
    ];

  const resize =
    settings.get(widgetSettingsParams.imageCrop) === ImageCrop.FIT
      ? ImageResizeOptions.contain
      : ImageResizeOptions.cover;

  return (
    <Card>
      <Box direction={getDirection(imageLayout, even)} height="240px">
        <Show if={settings.get(settingsParams.showImage)}>
          <UISref
            state="group"
            params={{ slug: group.slug }}
            bi={groupsLivesiteClick({
              screen_name: biParams.groupsScreenWithTab(),
              button_name: 'click_on_group',
            })}
          >
            <a className={classes.image}>
              <GroupImage
                fluid
                aspectRatio={ImageRatio.rectangle}
                resize={resize}
                image={group.coverImage?.image}
              />
            </a>
          </UISref>
        </Show>
        <CardContent verticalAlign="middle" align={alignment}>
          <Box align={alignment} verticalAlign="middle">
            <UISref
              state="group"
              params={{ slug: group.slug }}
              bi={groupsLivesiteClick({
                screen_name: biParams.groupsScreenWithTab(),
                button_name: 'click_on_group',
              })}
            >
              <Typography className={groupsStyles.groupTitle} as="a">
                {group.name}
              </Typography>
            </UISref>
            <Show if={!isMobile}>
              <RoleIcon role={group.role} />
            </Show>
          </Box>
          <GroupInfo groupId={group.id as string} align={alignment} />
          <Show if={settings.get(groupsListWidgetSettingsParams.showButton)}>
            <GroupGridItemAction group={group} fullWidth={false} />
          </Show>
        </CardContent>
      </Box>
    </Card>
  );
}

GroupStripeItem.displayName = 'GroupStripeItem';
