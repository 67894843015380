import React from 'react';
import cls from 'classnames';

import { TPAComponentProps } from 'wix-ui-tpa/dist/src/types';

import { classes } from './ButtonGroup.st.css';

interface IButtonGroupProps extends TPAComponentProps {
  as?: React.ElementType;
  orientation?: 'horizontal' | 'vertical';

  children: React.ReactNode;
}

export function ButtonGroup(props: IButtonGroupProps) {
  const Element = props.as as React.ElementType;
  return (
    <Element
      data-hook={props['data-hook']}
      className={cls(classes.root, props.className, {
        [classes.vertical]: props.orientation === 'vertical',
        [classes.horizontal]: props.orientation === 'horizontal',
      })}
    >
      {props.children}
    </Element>
  );
}

ButtonGroup.displayName = 'ButtonGroup';
ButtonGroup.defaultProps = {
  as: 'div',
  orientation: 'horizontal',
};
