import React from 'react';
import cls from 'classnames';
import { Tooltip as TooltipTPA, TooltipProps, TooltipSkin } from 'wix-ui-tpa';

import { Typography } from '../Typography';
import { usePortalContainer } from '../Portal';
import { stVars } from '../theme/z-index.st.css';

import { classes } from './Tooltip.st.css';

export interface IProps extends TooltipProps {}

export function Tooltip(props: IProps) {
  const { className, content, ...rest } = props;

  const container = usePortalContainer();

  if (!content) {
    return <>{props.children ?? null}</>;
  }

  return (
    <TooltipTPA
      fixed
      showArrow
      appendTo={container}
      skin={TooltipSkin.Wired}
      disableClickOutsideWhenClosed
      className={cls(classes.root, className)}
      zIndex={stVars.zIndex_TOOLTIP as unknown as number}
      content={
        <Typography variant="p2-12" as="div">
          {content}
        </Typography>
      }
      {...rest}
    />
  );
}

Tooltip.displayName = 'Tooltip';
Tooltip.defaultProps = {};
