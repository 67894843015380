import React from 'react';
import cls from 'classnames';
import { useSelector } from 'react-redux';

import { useEnvironment } from '@wix/yoshi-flow-editor';

import { groupsSettingsParams } from 'settings/groups/settings';
import { useSettings } from '@wix/tpa-settings/react';

import type { IGroup } from 'store/groups';
import { selectIsJoinedGroupMember } from 'store/selectors';

import { ListItem } from 'wui/ListItem';
import { ListItemText } from 'wui/ListItemText';
import { Skeleton } from 'wui/Skeleton';
import { Show } from 'wui/Show';

import { GroupListItemImageSkeleton } from './GroupListItemImage';
import { JoinedGroupListItem } from './JoinedGroupListItem';
import { SuggestedGroupListItem } from './SuggestedGroupListItem';

import { classes } from '../../ListLayout.st.css';

interface IGroupListItemProps {
  group: IGroup;
}

export function GroupListItem(props: IGroupListItemProps) {
  const { group } = props;

  const isJoined = useSelector(selectIsJoinedGroupMember(group.id as string));

  if (isJoined) {
    return <JoinedGroupListItem group={group} />;
  }

  return <SuggestedGroupListItem group={group} />;
}

export function GroupListItemSkeleton() {
  const { isMobile } = useEnvironment();
  const settings = useSettings();

  return (
    <ListItem
      disablePadding={!isMobile}
      className={
        isMobile
          ? cls(classes.halfVerticalSpacing, classes.horizontalSpacing)
          : undefined
      }
    >
      <Show if={settings.get(groupsSettingsParams.showImage)}>
        <GroupListItemImageSkeleton />
      </Show>
      <ListItemText
        title={
          <Skeleton
            className={classes.groupTitle}
            variant="text"
            width={isMobile ? '50%' : '40%'}
          />
        }
        subtitle={
          <Skeleton
            className={classes.groupInfo}
            variant="text"
            width={isMobile ? '80%' : '30%'}
          />
        }
      />
    </ListItem>
  );
}
