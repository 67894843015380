import React from 'react';
import cls from 'classnames';

import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import {
  Button as TPAButton,
  ButtonPriority,
  ButtonProps as TPAButtonProps,
  Spinner,
  ButtonSize,
} from 'wix-ui-tpa';

import { ButtonType } from 'settings/consts';
import { appearanceSettingsParams } from 'settings/appearance';

import { classes } from './Button.st.css';

export interface IButtonProps extends Omit<TPAButtonProps, 'priority'> {
  bw?: boolean;
  loading?: boolean;
  secondary?: boolean;
  fullWidth?: boolean;
}

export const Button: React.FC<IButtonProps> = (props) => {
  const { bw, className, children, secondary, loading, fullWidth, ...rest } =
    props;

  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const buttonType = settings.get(appearanceSettingsParams.buttonType);

  return (
    <TPAButton
      upgrade
      children={
        loading ? (
          <Spinner className={classes.spinner} diameter={20} />
        ) : (
          children
        )
      }
      priority={secondary ? ButtonPriority.secondary : ButtonPriority.primary}
      className={cls(classes.root, className, {
        [classes.bw]: bw,
        [classes.mobile]: isMobile,
        [classes.loading]: loading,
        [classes.primary]: !secondary,
        [classes.secondary]: secondary,
        [classes.fullWidth]: fullWidth,
        [classes.rounded]: buttonType === ButtonType.rounded,
        [classes.rectangle]: buttonType === ButtonType.rectangle,
      })}
      fullWidth={fullWidth}
      {...rest}
    />
  );
};

Button.displayName = 'Button';

export { ButtonSize };
