import React from 'react';
import cls from 'classnames';

import { TextField as TextFieldTPA, TextFieldProps } from 'wix-ui-tpa';

import { classes } from './TextField.st.css';

interface ITextFieldProps extends TextFieldProps {
  bw?: boolean;
}

export function TextField(props: ITextFieldProps) {
  const { className, bw, ...rest } = props;

  return (
    <TextFieldTPA
      newErrorMessage
      className={cls(classes.root, className, {
        [classes.bw]: bw,
      })}
      {...rest}
    />
  );
}

TextField.displayName = 'TextField';
