import React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import type {
  OverridableComponent,
  OverridableComponentProps,
} from '../OverridableComponent';
import { Box } from '../Box';
import { Hide } from '../Hide';
import { Show } from '../Show';
import { Fade } from '../Fade';

interface IStackProps extends React.ComponentProps<typeof Box> {
  separator?: React.ReactElement;
  children: React.ReactNode;
}

export const Stack = React.forwardRef(
  (
    props: OverridableComponentProps<IStackProps, 'div'>,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const { separator, children, ...rest } = props;

    return (
      <Box ref={ref} {...rest}>
        {separator ? join(children, separator) : children}
      </Box>
    );
  },
) as OverridableComponent<IStackProps, 'div'>;

Stack.displayName = 'Stack';

function join(children: React.ReactNode, separator: React.ReactElement): any {
  return React.Children.toArray(children)
    .filter(React.isValidElement)
    .map((child) => {
      const element = child as React.ReactElement;

      switch (element.type) {
        case Show:
          return element.props.if ? element.props.children : null;

        case Hide:
          return element.props.if ? null : element.props.children;

        case TransitionGroup:
          return React.cloneElement(element, {
            ...element.props,
            component: null,
            children: join(element.props.children, separator),
          });

        case Fade:
          return React.cloneElement(element, {
            ...element.props,
            children: <>{join(element.props.children, separator)}</>,
          });

        case React.Fragment:
          return element.props.children;

        default:
          return element;
      }
    })
    ?.flat()
    .filter(Boolean)
    .reduce((output, child, index, array) => {
      const Divider =
        index < array.length - 1
          ? React.cloneElement(separator, {
              key: `separator-${index}`,
            })
          : null;

      switch (child.type) {
        case Fade:
        case CSSTransition:
          return output.concat(
            React.cloneElement(child, {
              ...child.props,
              children: (
                <>
                  {child.props.children}
                  {Divider}
                </>
              ),
            }),
          );

        default:
          return output.concat([child, Divider]);
      }
    }, []);
}
