import React from 'react';
import cls from 'classnames';

import { classes } from './Divider.st.css';

import type { TPAComponentProps } from 'wix-ui-tpa/dist/src/types';

interface IDividerProps extends TPAComponentProps {
  bw?: boolean;
  inset?: boolean;
}

export function Divider(props: IDividerProps) {
  const { inset, className, bw, ...rest } = props;
  return (
    <hr
      className={cls(classes.root, className, {
        [classes.bw]: bw,
        [classes.inset]: inset,
      })}
      {...rest}
    />
  );
}
