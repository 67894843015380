import React from 'react';
import cls from 'classnames';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { Text, TextPriority, TextProps } from 'wix-ui-tpa';

import { classes } from './Typography.st.css';

// see https://zeroheight.com/7sjjzhgo2/p/00b9e9-typography/t/106766
export type Variant =
  | 'h2-32'
  | 'h2-24'
  | 'h2-20'
  | 'p2-20'
  | 'p2-16'
  | 'p2-14'
  | 'p2-12';

interface ITypographyProps
  extends Omit<TextProps, 'typography' | 'priority' | 'tagName'> {
  as?: React.ElementType | string;
  variant?: Variant;
  children?: React.ReactNode;

  secondary?: boolean;
  noWrap?: boolean;
  align?: React.CSSProperties['textAlign'];
}

export function Typography(props: ITypographyProps) {
  const {
    as,
    className,
    secondary,
    noWrap,
    align,
    variant: _variant,
    ...rest
  } = props;

  const { isMobile } = useEnvironment();

  const variant = props.variant || (isMobile ? 'p2-14' : 'p2-16');

  return (
    <Text
      tagName={props.as as string}
      priority={secondary ? TextPriority.secondary : TextPriority.primary}
      className={cls(classes.root, className, classes[variant], {
        [classes.noWrap]: noWrap,
        [classes.left]: align === 'left',
        [classes.right]: align === 'right',
        [classes.center]: align === 'center',
      })}
      {...rest}
    />
  );
}

Typography.displayName = 'Typography';
Typography.defaultProps = {
  as: 'p',
};
