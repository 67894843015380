import React from 'react';

import {
  ErrorMonitorBoundary,
  useEnvironment,
  useTranslation,
} from '@wix/yoshi-flow-editor';

import { useSettings } from '@wix/tpa-settings/react';
import settingsParams from 'Groups/settingsParams';

import type { PagingMetadata } from '@wix/ambassador-social-groups-v2-group/types';

import { IGroup, IGroupListOperationStatus } from 'store/groups';

import { ChevronDown as ChevronDownIcon } from '@wix/wix-ui-icons-common/on-stage';
import { Grid, TextButton, TextButtonPriority } from 'wix-ui-tpa';
import { Box } from 'wui/Box';
import { Show } from 'wui/Show';
import { Fade } from 'wui/Fade';
import { ErrorState } from 'wui/ErrorState';

import { GroupGridItem, GroupGridItemSkeleton } from './GroupGridItem';

import { classes } from './GroupGrid.st.css';

interface IGroupGridProps {
  groups: IGroup[];
  meta: PagingMetadata;
  status: IGroupListOperationStatus;

  emptyState: React.ReactElement;

  batchLength?: number;

  onRetry(): void;
  onLoadMore?(): void;
}

function getPlaceholders(count: number) {
  return new Array(count).fill(0).map((_, index) => (
    <Fade key={index}>
      <GroupGridItemSkeleton key={index} />
    </Fade>
  ));
}

export function GroupGrid(props: IGroupGridProps) {
  const { groups, meta, status } = props;

  const { isMobile } = useEnvironment();
  const { t } = useTranslation();

  const settings = useSettings();

  const hasMore = groups.length < (meta.total as number);
  const maxColumns = isMobile ? 1 : 3;

  if (status.fetch?.loading) {
    return (
      <Grid
        id="group-list-grid"
        maxColumns={maxColumns}
        columnGap={settings.get(settingsParams.gridCardSpacing)}
        rowGap={settings.get(settingsParams.gridCardSpacing)}
        className={classes.noOverflow}
      >
        {getPlaceholders(props.batchLength as number)}
      </Grid>
    );
  }

  if (status.fetch?.error) {
    return (
      <ErrorState
        onRetry={props.onRetry}
        subtitle={t('groups-web.toast.error.groups.query')}
      />
    );
  }

  if (!groups.length) {
    return props.emptyState;
  }

  return (
    <ErrorMonitorBoundary fallback={<ErrorState unhandled />}>
      <Grid
        id="group-list-grid"
        maxColumns={maxColumns}
        columnGap={settings.get(settingsParams.gridCardSpacing)}
        rowGap={settings.get(settingsParams.gridCardSpacing)}
        className={classes.noOverflow}
      >
        {groups.map((group) => (
          <Grid.Item key={group.id}>
            <GroupGridItem group={group} />
          </Grid.Item>
        ))}
        <Show if={status.fetchMore?.loading}>
          {getPlaceholders(props.batchLength as number)}
        </Show>
      </Grid>
      <Show if={hasMore && !!props.onLoadMore}>
        <Box
          padding={isMobile ? 'SP0 SP5' : undefined}
          marginTop={isMobile ? 'SP3' : 'SP4'}
        >
          <TextButton
            onClick={props.onLoadMore}
            prefixIcon={<ChevronDownIcon />}
            priority={TextButtonPriority.primary}
          >
            {t('groups-web.showMore')}
          </TextButton>
        </Box>
      </Show>
    </ErrorMonitorBoundary>
  );
}

GroupGrid.displayName = 'GroupGrid';
GroupGrid.defaultProps = {
  batchLength: 6,
};
