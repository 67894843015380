import {
  IStyleParam,
  StyleParamType,
  wixColorParam,
  wixFontParam,
} from '@wix/tpa-settings';
import {
  FontTheme,
  POST_ICONS_COLOR,
  POST_PLACEHOLDER_COLOR,
  POST_TEXT_COLOR,
} from '../consts';
import { doNotTransformSettingsKey } from 'settings/helpers';

export type IFeedStylesParams = {
  postsTextColor: IStyleParam<StyleParamType.Color>;
  postsTextFont: IStyleParam<StyleParamType.Font>;
  createNewPostColor: IStyleParam<StyleParamType.Color>;
  createNewPostFont: IStyleParam<StyleParamType.Font>;
  memberNameColor: IStyleParam<StyleParamType.Color>;
  memberNameFont: IStyleParam<StyleParamType.Font>;
  postingDateColor: IStyleParam<StyleParamType.Color>;
  postingDateFont: IStyleParam<StyleParamType.Font>;
  // TODO:
  // --- start: is not using in central feed, why? ---
  postsBackgroundColor: IStyleParam<StyleParamType.Color>;
  postsBorderColor: IStyleParam<StyleParamType.Color>;
  postsBorderWidth: IStyleParam<StyleParamType.Number>;
  mobilePostsTextFontSize: IStyleParam<StyleParamType.Number>;
  postIconsColor: IStyleParam<StyleParamType.Color>;
  // --- end: is not using in central feed, why? ---
  feedTitleColor: IStyleParam<StyleParamType.Color>;
  feedTitleFont: IStyleParam<StyleParamType.Font>;
};

export const feedStylesParams: IFeedStylesParams = {
  postsTextColor: {
    key: 'postsTextColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam(POST_TEXT_COLOR),
  },
  postsTextFont: {
    key: 'postsTextFont',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  createNewPostColor: {
    key: 'createNewPostColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam(POST_PLACEHOLDER_COLOR),
  },
  createNewPostFont: {
    key: 'createNewPostFont',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 14,
    }),
  },
  memberNameColor: {
    key: 'memberNameColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam(POST_TEXT_COLOR),
  },
  memberNameFont: {
    key: 'memberNameFont',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  postingDateColor: {
    key: 'postingDateColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam(POST_TEXT_COLOR),
  },
  postingDateFont: {
    key: 'postingDateFont',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 14,
    }),
  },
  postsBackgroundColor: {
    key: 'postsBackgroundColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  postsBorderColor: {
    key: 'postsBorderColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-3'),
  },
  postsBorderWidth: {
    key: 'postsBorderWidth',
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  mobilePostsTextFontSize: {
    key: 'mobilePostsTextFontSize',
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
    dangerousKeyTransformationOverride: doNotTransformSettingsKey,
  },
  postIconsColor: {
    key: 'postIconsColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam(POST_ICONS_COLOR),
  },
  feedTitleFont: {
    key: 'feedTitleFont',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam(FontTheme.HEADING_1, {
      size: 20,
      htmlTag: 'h1',
    }),
  },
  feedTitleColor: {
    key: 'feedTitleColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
};
