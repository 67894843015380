import React, { useEffect, useRef } from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { classes } from './ScrollLock.st.css';

export function useScrollBlock(blocked?: boolean, enabled = true) {
  const { isSSR } = useEnvironment();

  const eligible = useRef(false);

  useEffect(() => {
    if (isSSR || !enabled) {
      return;
    }

    blocked ? lock() : unlock();

    return () => unlock();
  }, [isSSR, blocked, enabled]);

  function lock() {
    const isLocked = document.body.classList.contains(classes.lock);

    eligible.current = !isLocked;

    if (!isLocked) {
      document.body.classList.add(classes.lock);
    }
  }

  function unlock() {
    if (eligible.current) {
      document.body.classList.remove(classes.lock);
    }
  }
}
