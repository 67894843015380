import React from 'react';
import cls from 'classnames';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { Paper } from '../Paper';
import { Typography } from '../Typography';

import { classes } from './EmptyState.st.css';

type EmptyStateVariant = 'page' | 'section' | 'page-borderless';

interface IEmptyStateProps
  extends Omit<React.ComponentProps<typeof Paper>, 'title'> {
  centered?: boolean;
  variant?: EmptyStateVariant;

  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  action?: React.ReactElement;
}

export function EmptyState(props: IEmptyStateProps) {
  const {
    className,
    centered,
    variant,
    action,
    title: _,
    subtitle: __,
    ...rest
  } = props;

  const { isMobile } = useEnvironment();

  let title: React.ReactElement;
  let subtitle: React.ReactElement;

  const isSection = variant === 'section';
  const isBorderless = variant === 'page-borderless';
  const isPage = variant === 'page' || variant === 'page-borderless';
  const isLarge = isPage && !isMobile;
  const isBordered = !isBorderless && isPage;

  if (props.title && (props.title as React.ReactElement).type !== Typography) {
    title = (
      <Typography variant={isLarge ? 'h2-24' : 'h2-20'}>
        {props.title}
      </Typography>
    );
  } else {
    title = props.title as React.ReactElement;
  }

  if (
    props.subtitle &&
    (props.subtitle as React.ReactElement).type !== Typography
  ) {
    subtitle = <Typography variant="p2-16">{props.subtitle}</Typography>;
  } else {
    subtitle = props.subtitle as React.ReactElement;
  }

  return (
    <Paper
      shadow={isBordered}
      topBottomBorders={isBordered}
      sideBorders={isBordered && !isMobile}
      className={cls(classes.root, className, {
        [classes.mobile]: isMobile,
        [classes.centered]: centered,
        [classes.page]: isPage,
        [classes.section]: isSection,
      })}
      {...rest}
    >
      {title &&
        React.cloneElement(title, {
          ...title.props,
          className: cls(title.props.className, classes.title),
        })}

      {subtitle &&
        React.cloneElement(subtitle, {
          ...subtitle.props,
          className: cls(subtitle.props.className, classes.subtitle),
        })}

      {action &&
        React.cloneElement(action, {
          ...action.props,
          className: cls(action.props.className, classes.action),
        })}
    </Paper>
  );
}

EmptyState.displayName = 'EmptyState';
EmptyState.defaultProps = {
  centered: true,
  variant: 'page' as EmptyStateVariant,
};
